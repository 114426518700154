
import { computed } from '@vue/composition-api';

export default function userHelper({ root }) {

    const store = root.$store;

    const user = computed(() => store.getters['user']);

    const isInGroup = (group) => {
        return group ? user.value.groups.includes(group) : true;
    };

    return {
        user,
        isInGroup
    };
}
