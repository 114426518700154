import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VRow,{staticClass:"mt-5"},_vm._l((_vm.categories),function(cat,i){return _c(VCol,{key:i,attrs:{"cols":"12","sm":"6","md":"4"}},[_c(VCard,{staticClass:"fill-height",attrs:{"rounded":"xl"}},[_c(VCardText,[_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"pa-11",staticStyle:{"cursor":"auto"},attrs:{"color":"blue lighten-2","ripple":false,"icon":"","outlined":""}},[_c(VIcon,{attrs:{"size":"50","outlined":""},domProps:{"textContent":_vm._s(cat.icon)}})],1),_c('div',{staticClass:"text-h5 font-weight-light",domProps:{"textContent":_vm._s(cat.name)}})],1)]),(cat.subLinks)?[_c(VDivider),_c(VCardText,{staticClass:"pt-0"},[_c(VList,{attrs:{"rounded":"","dense":""}},_vm._l((cat.subLinks),function(lnk,i2){return _c(VListItem,{key:i2,attrs:{"href":lnk.href,"target":lnk.target,"to":lnk.to}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(lnk.name)}})],1)],1)}),1)],1)]:_vm._e()],2)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }